import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import Alert from "../../Alert";
import { setTtsData } from "../../../actions/videoAction";
import UploadVoiceOver from '../VoiceOver/UploadVoiceOver'
import swal from "sweetalert";
import { Tab, Nav } from "react-bootstrap";
import Recording from "./Recording";
import Sonority from "./Sonority";


let ttsAudio = false;
let scriptText, originalText
const VoiceOver = ({ slideData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);
    const selectedSlide = slideData.find(({ is_selected }) => is_selected === true);
    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true);
    const [ttsStatus, setTtsStatus] = useState(true);  // for chacking tts genrate status
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [translateLang, setTranslateLang] = useState([]);
    const [translateActive, setTranslateActive] = useState(false)
    const [translateCode, setTranslateCode] = useState('')
    const [transLoader, setTransLoader] = useState({
        revertButton: false,
        translateLoader: false
    })
    const [audio, setAudio] = useState({
        url: false,
        duration: 0,
        tts: {
            text: '',
            languageId: "",
            voiceId: "",
            translateText: "",
            engine: "",
        }
    });


    useEffect(() => {
        if (selectedSlide) {
            if (selectedSlide.audio.tts.text === '') {
                if (selectedSlide.scriptText.length > 0) {
                    scriptText = selectedSlide.scriptText.find(({ is_seclected }) => is_seclected === true).text
                }
            }
            else {
                scriptText = selectedSlide.audio.tts.text
            }

            if (selectedSlide.audio.tts.translateText !== '') {
                originalText = selectedSlide.audio.tts.translateText
            }
            else {
                originalText = ""
            }
        }
    })

    useEffect(() => {
        if (pollyVoices.data.length > 0) {
            setAudio({
                ...audio,
                tts: {
                    ...audio.tts,
                    voiceId: pollyVoices.data[0].voice_id,
                    engine: pollyVoices.data[0].type
                }
            })
        }
    }, [pollyVoices.data])

    useEffect(() => {
        if (pollyLanguages.data.length > 0) {
            setAudio({
                ...audio,
                tts: {
                    ...audio.tts,
                    languageId: pollyLanguages.data[0].code
                }
            })
        }
    }, [pollyLanguages.data])


    useEffect(() => {
        setAudio({
            ...audio,
            tts: {
                ...audio.tts,
                text: scriptText
            }
        })
    }, [scriptText, selectedSlideIndex]);


    const changeTtsText = (e) => {

        setTtsStatus(true)
        setAudio({
            ...audio,
            tts: {
                ...audio.tts,
                text: e.target.value
            }
        });
    }

    const searchPollyLanguages = () => {

        axios({
            method: 'GET',
            url: `${baseURL}get-polly-languages`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setPollyLanguages({ ...pollyLanguages, data: res.data.data });
            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }

        }).catch(error => {
            dispatch(setAlert("Getting server error", "danger"));
        })
    }

    const searchPollyVoices = async (value) => {

        let formData = new FormData();
        formData.append('lang_code', value);

        await axios({
            method: 'POST',
            url: `${baseURL}get-artists`,
            data: formData,
            headers: { 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setPollyVoices({ ...pollyVoices, data: res.data.data });

            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }

        }).catch(error => {
            console.log(error)
        })
    }


    const generateTts = () => {

        if (audio.tts.text !== "") {
            setLoader(true);
            let engine
            if (audio.tts.engine === "Neural") {
                engine = audio.tts.engine.replace("Neural", "neural")
            }
            else {
                engine = audio.tts.engine.replace("Standard", "standard")
            }
            axios({
                method: 'POST',
                url: `${baseURL}generate-ttsnew`,
                data: JSON.stringify({
                    text: audio.tts.text,
                    language_id: audio.tts.languageId,
                    voice_id: audio.tts.voiceId,
                    engine: engine
                }),
                headers: { 'Authorization': auth.token }
            }).then(res => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, 'success'));
                    setTtsStatus(false)
                    handleTtsUrl(res.data.file_url, res.data.duration)
                    setLoader(false);
                } else {
                    dispatch(setAlert(res.data.message, 'danger'));
                    setTtsStatus(true)
                }
                setLoader(false);

            }).catch(error => {
                setTtsStatus(true)
                setLoader(false);
            })

        } else {
            swal("Oops!", "Please, add text in TTS");
        }
    }

    const getLanguage = (e) => {
        setTtsStatus(true)
        searchPollyVoices(e.target.value);
        setAudio({
            ...audio,
            tts: {
                ...audio.tts,
                languageId: e.target.value
            }
        });
    }

    const getVoices = (e) => {

        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        setTtsStatus(true)
        setAudio({
            ...audio,
            tts: {
                ...audio.tts,
                voiceId: data.voice_id,
                engine: data.type
            }
        });
    }

    const saveTts = () => {
        dispatch(setTtsData(audio, selectedSlideIndex, true))
    }

    const handleTtsUrl = (url, time) => {

        setAudio({
            ...audio,
            url: url,
            duration: time
        })
    }



    const playTts = (url) => {

        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-google-language`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTranslateLang(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        })

    }, [])

    const translateText = () => {
        const data = {
            lang_code: translateCode,
            text: audio.tts.text
        }
        setTransLoader({
            ...transLoader,
            translateLoader: true
        })
        axios({
            method: 'POST',
            url: `${baseURL}translate`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAudio({
                    ...audio,
                    tts: {
                        ...audio.tts,
                        text: res.data.data.text,
                        translateText: res.data.data.input
                    }
                })
                setTtsStatus(true)
                dispatch(setAlert(res.data.message, "success"))
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            setTransLoader({
                ...transLoader,
                translateLoader: false
            })
        }).catch(error => {
            setTransLoader({
                ...transLoader,
                translateLoader: false
            })
            console.log(error)
        })
    }

    const revertText = () => {
        setAudio({
            ...audio,
            tts: {
                ...audio.tts,
                text: selectedSlide.audio.tts.translateText,
            }
        })
        originalText = ""
        setTransLoader({
            ...transLoader,
            revertButton: false
        })
        console.log(audio)
    }
  


    useEffect(() => {
        searchPollyLanguages();
        searchPollyVoices('en-US');

        return () => {
            if (ttsAudio !== false) {
                ttsAudio.pause();
            }
        }
    }, [])

    useEffect(() => {
        setTtsStatus(true)
    }, [selectedSlideIndex])

    return (
        <>
            <Alert />
            <div className="tabInner">
                <div className="media-block">
                    <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-tab">
                        <Nav variant="pills" className="inTabNav full voice-over">
                            <Nav.Item>
                                <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Upload-audio">Upload Voiceover</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sonority-tab">Sonority</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Recording">Recording</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>

                            <Tab.Pane eventKey="voice-tab">
                                <div className="innertab">
                                    <div className="alert alert-warning text-center">
                                        This voiceover would only affect the current slide
                                    </div>
                                    <div className="innertab-scroll alt">
                                        <div className="ai-voice-block">
                                            <div className="voice-block-top">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input className="form-control" type="text" name="slide" value={selectedSlideIndex + 1} readOnly />
                                                        <span className="optDesc">SLIDE</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <select onChange={(e) => getLanguage(e)} className="cursor-pointer text-hover-effect">
                                                            {
                                                                pollyLanguages.data.length > 0 ?
                                                                    pollyLanguages.data.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.code}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </select>
                                                        <span className="optDesc">LANGUAGE</span>
                                                    </div>
                                                    <div className="col-5">
                                                        <select onChange={(e) => getVoices(e)} className="cursor-pointer text-hover-effect">
                                                            {
                                                                pollyVoices.data.length > 0 ?
                                                                    pollyVoices.data.map((item, index) => {

                                                                        return (
                                                                            <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </select>
                                                        <span className="optDesc">VOICE</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="voice-block-mid">
                                                <textarea
                                                    placeholder="Add text here"
                                                    onChange={(e) => changeTtsText(e)}
                                                    value={audio.tts.text}
                                                />
                                            </div>
                                            <div className="voice-block-bottom">
                                                <div className="row">
                                                    <div className="col-6">

                                                        {ttsStatus ?
                                                            <button disabled={loader} className="demoLink btn-block" onClick={() => generateTts()}>
                                                                <span className="mr-1">
                                                                    {loader ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-file-upload" />}
                                                                </span>
                                                                Generate
                                                            </button>
                                                            :
                                                            <button className="demoLink btn-block " onClick={() => playTts(audio.url)}>
                                                                <span className="mr-1">
                                                                    <i className={`fas ${currentTts === audio.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                                                </span>
                                                                Preview
                                                            </button>
                                                        }
                                                    </div>


                                                    <div className="col-6">
                                                        <button onClick={() => saveTts()} className="demoLink btn-block" disabled={ttsStatus}>
                                                            <span className="mr-1">
                                                                <i className="far fa-save" />
                                                            </span>{" "}
                                                            Save &amp; Use
                                                        </button>

                                                        {/* <button className="demoLink btn-block"><img src={iconSave} alt=""/> Save & Use</button> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="add-block-single">
                                            <div className="add-block-main">
                                                <div className="add-block-title mb-4">
                                                    <h2>Translate Text</h2>
                                                    <div className="switch-single">
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                id="flexSwitchCheckChecked"
                                                                value={translateActive}
                                                                onChange={(e) => setTranslateActive(e.target.checked)}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {translateActive ?
                                                    <div className="mediaList">
                                                        <div className="voice-block-top">
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <select onChange={(e) => setTranslateCode(e.target.value)} className="cursor-pointer">
                                                                        <option disabled selected>Select Language</option>
                                                                        {translateLang.length > 0 ?
                                                                            translateLang.map((curElem) => {
                                                                                return (
                                                                                    <option
                                                                                        value={curElem.code}
                                                                                        key={curElem.id}
                                                                                    >
                                                                                        {curElem.name}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                            : ''}
                                                                    </select>
                                                                </div>
                                                                <div className="col-5 text-right">
                                                                    {originalText === "" ?
                                                                        <button onClick={() => translateText()} className="translate-btn ">
                                                                            Translate {transLoader.translateLoader ? <i className="fa fa-spinner fa-spin" /> : ''}
                                                                        </button> :
                                                                        <button onClick={() => revertText()} className="translate-btn ">
                                                                            Revert Text {transLoader.translateLoader ? <i className="fa fa-spinner fa-spin" /> : ''}
                                                                        </button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tab.Pane>

                            {/* voice-Recording */}

                            <Tab.Pane eventKey="Recording">
                                <Recording
                                    selectedSlideIndex={selectedSlideIndex}
                                />
                            </Tab.Pane>

                            <Tab.Pane eventKey="sonority-tab">
                                <Sonority
                                    selectedSlideIndex={selectedSlideIndex}
                                />
                            </Tab.Pane>

                            <Tab.Pane eventKey="Upload-audio">
                                <UploadVoiceOver
                                    selectedSlideIndex={selectedSlideIndex}
                                />
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </div>
            </div>
        </>
    );
}

export default VoiceOver;
