import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addBgMusic, setTtsData, removeTtsFromSlides, removeBgAudioFromSlides } from "../../actions/videoAction";
import SweetAlert from "react-bootstrap-sweetalert";
import waveIcon from "../../images/wave.svg";

let audio = false;
const AudioFiles = ({ title, url, currentUrl, setCurrentUrl, type, selectedAudio, setSelectedAudio, removeMedia, id, selectedSlideIndex, voiceOverName }) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');

    let clearPause;
    const playAudio = (url) => {
        if (audio !== false) {
            audio.pause();
            setPlay(false);
        }
        setLoader(true);
        audio = new Audio(url);
        audio.play()
            .then(r => setLoader(false)).catch(err => console.log(err));
        setCurrentUrl(url);
        setPlay(true)
        clearPause = setTimeout(() => {
            pauseAudio()
        }, audioDuration * 1000)
    }

    const pauseAudio = (url) => {

        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const selectAudio = (url) => {
        if (title === "Uploaded VoiceOver") {
            const audio = {
                url: url,
                duration: parseInt(audioDuration.toFixed()) + 3, 
                tts: {
                    text: "",
                    languageId: "",
                    voiceId: "",
                    translateText: ""
                }
            }
            setSelectedAudio(url);
            dispatch(setTtsData(audio, selectedSlideIndex, true))
        }
        else {
            setSelectedAudio(url);
            dispatch(addBgMusic(url, selectedSlideIndex))
        }

    }

    const removeAudio = () => {
        setSelectedAudio(false);
        if (title === "Uploaded VoiceOver") {
            dispatch(removeTtsFromSlides(selectedSlideIndex))
        }
        else {
            dispatch(removeBgAudioFromSlides(selectedSlideIndex))
        }
    }

    const onConfirm = async () => {
        setButtonText('Deleting...');
        await removeMedia(id);
        setShowDelete(false);
        setButtonText('Delete');
    }

    const showPopUp = (id) => {
        setShowDelete(true);
    }

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    useEffect(() => {
        let au = document.createElement('audio');
        au.src = url;
        au.addEventListener('loadedmetadata', function () {
            setAudioDuration(Number((au.duration).toFixed(1)));
        }, false);
    }, [])

    return (
        <>
            <li className="cursor-pointer col-md-6" >
                <div className={`audioList-single card ${selectedAudio === url ? 'active-audio' : ''}`}>
                    <div className="active-audio-wrapper cursor-pointer" onClick={() => removeAudio(url)}>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="audioList-top">

                        {
                            type === "uploaded" ?
                                <div className="card-icon-delete" onClick={() => showPopUp()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </div>
                                : ''
                        }
                        <img className="waveIcon" alt="" src={waveIcon} onClick={() => selectAudio(url)} />
                        <span className="audio-time">{`${audioDuration} sec`}</span>
                    </div>
                    <div className="audioList-bottom">
                        {
                            play && currentUrl === url ? <i className="fa fa-pause-circle" aria-hidden="true" onClick={() => pauseAudio(url)} />
                                : <i className={`${loader ? 'fa fa-spinner fa-spin' : 'fas fa-play-circle'} `} onClick={() => playAudio(url)} />
                        }
                        {
                            title === "Uploaded VoiceOver" ? <p className="mx-1">{voiceOverName}</p> : <p className="mx-1">{title}</p>

                        }

                    </div>
                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this file
                </SweetAlert>
            </li>



        </>
    )
}

export default AudioFiles;