import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import logo from "../../images/LOGO.png"
import loginBg from "../../images/BG.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { GoMail } from "react-icons/go";
import Title from "../Title";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        email: ''
    })

    const onInputChange = (e) => {
        setEmailDetails({ ...emailDetails, email: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(forgetPassword(emailDetails, setLoader));
    }

    return (
        <>
            <Title title="Forget Password" />
            <Alert />
            <section className="logoimg" style={{ backgroundImage: "url(" + loginBg + ")" }}>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="login-box">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center">
                                {/* <div className="login-content text-white ">
                                    <span className="welcome-text">WELCOME TO  </span><br />
                                    <h1> ReviewReel</h1>
                                    <p >Instantly create impactful, engaging, and ready-to-upload product video reviews in any niche using ReviewReel’s powerful A.I. that does all the work for you.</p>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div> */}
                                <div className="login-content text-white ">
                                    <div className="login-head-text ">
                                        <span className="welcome-text">WELCOME TO  </span><br />
                                        <h1> ReviewReel</h1>
                                    </div>
                                    <div className="login-head-para ">
                                        <p >Instantly create impactful, engaging, and ready-to-upload product video reviews in any niche using ReviewReel’s powerful A.I. that does all the work for you.</p>
                                    </div>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="login-form text-white" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                    <h4><span>Enter </span> Your Email Address</h4>
                                    <div className="form-group mt-4">
                                        <label>Email Address</label>
                                        <div className="form-group2">
                                            <span className="mail "> <GoMail /></span>
                                            <span className=" line"> </span>
                                            <input type="email" className="form-control" placeholder="Enter Email"
                                                required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="form-control btn-change6 mt-4" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit
                                    </button>
                                    <div className="forgot text-center mt-4">
                                        <Link to="/login" className="text-center ">
                                            Remember Login ? <span>Click here</span></Link>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div >

                <LoginFooter />

            </section >

        </>
    )
}

export default ForgetPassword;