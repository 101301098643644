import React from "react";
import EditTeamClient from "./EditTeamClient";

const ManageClient = ({ users, fetchClientTeam, type }) => {

    return (
        <>
            <div className="row mt-5">
                <div className="col-md-12 text-white mt-5">
                    <h5>Manage {type ? type : 'Client'} Account </h5>
                    <div className="team-member-box mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-striped table-dark table-responsive-lg ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.length > 0 ?
                                                users.map((user, index) => {
                                                    return (
                                                        <>
                                                            <EditTeamClient
                                                                type={`${type ? type : 'Client'} Account`}
                                                                user={user}
                                                                index={index}
                                                                fetchClientTeam={fetchClientTeam}
                                                                key={`client_${index}`}
                                                            />
                                                        </>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageClient;