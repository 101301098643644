import React from "react";
import reelapps from "../../images/ReelApps.png";

const LoginFooter = () => {
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 d-flex align-items-center">
                            <div className="footer-menu">
                                <ul className="list-inline m-0 d-flex">
                                    <li><a href="http://reelapps.io/terms.html" target="_blank">TERMS</a></li>
                                    <li><a href="http://reelapps.io/privacy.html" target="_blank">PRIVACY</a></li>
                                    <li><a href="http://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 d-flex align-items-center justify-content-center">
                            <div className="footer-copyright text-white text-center ">
                                <p className="m-0">
                                    © 2022, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by<a href="https://vega6.com/" target="_blank" className="ml-1">Vega6</a>
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-2 d-flex align-items-center justify-content-lg-end justify-content-center">
                            <div className="vineasx-logo">
                                <a href="https://reelapps.io/" target="_blank"><img src={reelapps} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginFooter;