import React, { useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert";

const EditTeamClient = ({ type, user, index, fetchClientTeam }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [accountDetails, setAccountDetails] = useState({
        id: user.id,
        email: user.email,
        name: user.name,
        password: ''
    });

    const [deleteId, setDeleteId] = useState({
        client_id: null
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = () => {
        deleteClientTeam();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const onButtonClick = (e, id) => {
        e.preventDefault();

        setDeleteId({ ...deleteId, client_id: id });
        setShowDelete(true);
    }


    const onInputChange = (e) => {
        setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
    }

    const editClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}edit-client-account`,
            data: accountDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchClientTeam()
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const deleteClientTeam = () => {
        setButton('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-client`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchClientTeam()
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
            setButton('Delete');
        }).catch(err => {
            setLoader(false);
            setButton('Delete');
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        editClientTeam();
    }

    return (
        <>
            <Alert />
            <tr key={index}>
                <th scope="row"><h6
                    className="text-wrapper m-0">{user?.name}</h6></th>
                <td><h6 className="text-wrapper m-0">{user?.email}</h6></td>
                {/* <td>{type}</td> */}
                <td>{user?.created}</td>
                <td className="expand-button">
                    <a className="action-icon delete-icon remove mb-1 mt-md-1"
                        title="Edit"
                        data-toggle="collapse" data-parent="accordion89"
                        href={`#client_${user?.id}`}
                        aria-expanded="true"><i className="fas fa-edit"></i>
                    </a>
                    <a className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                        style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                        onClick={(e) => onButtonClick(e, user.id)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </td>
            </tr>

            <tr className="hide-table-padding">
                <td colSpan="12" style={{ padding: "0px" }}>
                    <div id={`client_${user?.id}`} className="p-3 in collapse" style={{ width: "100%" }}>
                        <div className="col-12">
                            <p className="text-white"></p>
                            <div className="mt-2 mb-0">
                                <h6 className=" text-left mb-4 mt-3"
                                    style={{ marginLeft: "-10px" }}>Edit {type}</h6>
                                <div>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label className="input-label-pos m-0">
                                                        Name
                                                    </label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info w-100"
                                                        type="text" autoComplete="off" name="name" defaultValue={accountDetails.name}
                                                        required onChange={(e) => onInputChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label className="input-label-pos m-0">Email
                                                        Address</label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="email" name="email"
                                                        placeholder="Email Address" readOnly
                                                        defaultValue={user.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label
                                                        className="input-label-pos m-0">Password <small>(leave
                                                            blank for same password)</small></label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="password" name="password"
                                                        placeholder="" autoComplete="off"
                                                        onChange={(e) => onInputChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-mb">
                                                    <label className="input-label-pos m-0">Account
                                                        Type</label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="text" name="Account Type"
                                                        placeholder="Account Type" readOnly
                                                        value={type} />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col col-md-12 cancel-update d-flex  justify-content-center text-center mt-5 mb-5">
                                            <div className="btn-cancl">
                                                <a type="button" className="cancl-btn   "
                                                    data-toggle="collapse"
                                                    data-parent="accordion89" href={`#client_${user.id}`}
                                                    aria-expanded="true"
                                                    style={{ display: "inline-block", marginRight: "10px" }}>Cancel</a>
                                            </div>
                                            <button type="submit"
                                                className="toggleupdate  btn-change7 "
                                                style={{ display: "inline-block", textTransform: "capitalize" }}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this {type}
            </SweetAlert>

        </>
    )
}

export default EditTeamClient;