import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [resellerData, setResellerData] = useState([]);


    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team_member',
        email: '',
        name: '',
        password: ''
    });

    console.log(userDetails)
    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                setTeamData(res.data.data.team_members)
                setClientData(res.data.data.clients)
            }
        }).catch(error => {

        })
    }

    const addReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}reseller`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchReseller()
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.log(error)
        })
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}add-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam()
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        })
    }
    const fetchReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-reseller-accounts`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setResellerData(res.data.accounts)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        if (userDetails.type === 'reseller') {
            addReseller()
        }
        else {
            addClientTeam();
        }

    }

    useEffect(() => {
        fetchClientTeam();
        // fetchReseller()
    }, []);

    return (
        <>
            <Alert />
            <div className="row mt-5">
                <div className="col-md-12 text-white ">
                    <h5>Create Account</h5>
                </div>
            </div>
            <form className="add-account-box mt-2" method="post" onSubmit={(e) => onFormSubmit(e)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Type</small>
                            <select className="form-control" name="type" onChange={(e) => onInputChange(e)}>
                                <option style={{ backgroundColor: "#000" }} value="team_member">Team Member</option>
                                <option style={{ backgroundColor: "#000" }} value="client">Client Account</option>
                                {/* <option style={{ backgroundColor: "#000" }} value="reseller">Reseller Account</option> */}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted  ">Account Name</small>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Account Name"
                                required
                                name="name"
                                id="account-name"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Email</small>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                required
                                id="account-email"
                                name="email"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Password</small>
                            <input
                                type="password"
                                className="form-control"
                                id="account-password"
                                placeholder="********"
                                required name="password"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                        <div className="add-account-save-btn">
                            <button type="submit" className="btn-change7" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                            </button>
                        </div>
                    </div>
                </div>

            </form>

            <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} />
            <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} />
            {/* <ManageClient users={resellerData} fetchClientTeam={fetchReseller} type="Reseller" /> */}

        </>
    )
}

export default AddAccount;