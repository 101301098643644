import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
import HoverVideoPlayer from "react-hover-video-player";
import { updateSlideImage, updateSlideVideo, updateSlideProductImage, updateSlideTextImage } from "../../../actions/videoAction";
import { setAlert } from "../../../actions/alert";
import Alert from "../../Alert";
import SweetAlert from "react-bootstrap-sweetalert";


const UploadMedia = ({ selectedSlideIndex, selectedTextIndex, selectImageLayer, slideData }) => {
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);

    const [cropModel, setCropModel] = useState(false);
    const [cropImg, setCropImg] = useState('');
    const [cropIndex, setCropIndex] = useState('');

    const [uploadedImage, setUploadedImage] = useState([]);
    const [uploadedVideo, setUploadedVideo] = useState([]);

    const [checkImage, setCheckImage] = useState(false);

    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const [mediaId, setMediaId] = useState(false);

    const openDeletePopUp = (id) => {
        setMediaId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        removeMedia(mediaId)
    }


    const fetchUserLibrary = () => {
        const image = { type: "image" }
        const video = { type: "video" }
        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-uploaded-file`,
            data: JSON.stringify(image),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let val = res.data.data
                val = val.reverse()
                setUploadedImage(val);
            }
            else {
                if (uploadedImage.length === 1) {
                    setUploadedImage([]);
                }
            }
        }).catch(err => {
            console.log(err)
        })

        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-uploaded-file`,
            data: JSON.stringify(video),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let val = res.data.data
                val = val.reverse()
                setUploadedVideo(val);

            }
            else {
                if (uploadedVideo.length === 1) {
                    setUploadedVideo([]);
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const selectImage = (image, index) => {
        setCheckImage(index);
        if (selectImageLayer) {
            if (slideData[selectedSlideIndex].text[selectedTextIndex].image && slideData[selectedSlideIndex].text.length > 0) {
                dispatch(updateSlideTextImage(image, selectedSlideIndex, selectedTextIndex))
            } else {
                dispatch(updateSlideProductImage(image, selectedSlideIndex))
            }
        }
        else {
            if (slideData[selectedSlideIndex].categoryID !== 10) {
                let jsonData = {
                    fileUrl: image,
                    poster: image,
                    type: 'image'
                }
                dispatch(updateSlideImage(jsonData, selectedSlideIndex))
            }
        }
    }

    const selectVideo = (video, index) => {
        setCheckImage(index);
        if (selectImageLayer) {
            if (slideData[selectedSlideIndex].text[selectedTextIndex].image && slideData[selectedSlideIndex].text.length > 0) {
                dispatch(updateSlideTextImage(video.url, selectedSlideIndex, selectedTextIndex))
            } else {
                dispatch(updateSlideProductImage(video.url, selectedSlideIndex))
            }
        }
        else {
            let jsonData = {
                fileUrl: video.url,
                poster: video.poster,
                type: 'video'
            }
            dispatch(updateSlideVideo(jsonData, selectedSlideIndex))

        }
    }

    const removeMedia = (id) => {
        setButton("Deleting...")
        let formData = new FormData();
        formData.append('id', id);
        axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchUserLibrary();
            }

            setButton("Delete")
            setShowDelete(false);
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
            setShowDelete(false);
            console.log(err)
        })
    }

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;
                let type = "image";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 20000000;
                    type = "video";
                }

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('type', type);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                fetchUserLibrary();
                            }
                            setLoader(false);
                            setPercent(0);
                        }).catch((error) => {
                            setLoader(false)
                            console.log(error)
                        })

                } else {
                    swal("Oops!", "Max allowed size for image 5MB for video 20MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    useEffect(() => {
        fetchUserLibrary();
    }, [])

    return (
        <>
            <Alert />
            <div className="innertab">
                <div className="tabSearch">
                    <button className="demoLink btn-block mt-0"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload Media</button>
                    <input className="absInp" type="file" onChange={(e) => onInputFile(e)} />
                </div>
                {
                    loader ?
                        <div className="progress mt-4">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%
                            </div>
                        </div>
                        : ''
                }
                <div className="innertab-scroll">
                    <div>
                        <ul className="row">

                            {uploadedImage.length > 0 ?
                                uploadedImage.map((curElem, index) => {
                                    return (

                                        <li className="col-md-6 cursor-pointer" key={index}>
                                            <div className="mediaList-single m-1" style={{ textAlign: "center" }}>
                                                <img className="mediaImg" alt="" style={{ maxHeight: '100%', width: "auto", }} src={curElem.url} onClick={() => selectImage(curElem.url, curElem.id)} />
                                            </div>
                                            {checkImage === curElem.id ?
                                                <div className="slide-active">
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                : ''
                                            }
                                            <div className="delete-media" onClick={() => openDeletePopUp(curElem.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </div>

                                        </li>
                                    )
                                }) : ''
                            }
                            {uploadedVideo.length > 0 ?
                                uploadedVideo.map((curElem) => {
                                    return (

                                        <li className="col-md-6 cursor-pointer" key={curElem.id} >
                                            <div onClick={() => selectVideo(curElem, curElem.id)}>
                                                <HoverVideoPlayer
                                                    className="m-1 "
                                                    videoSrc={curElem.url}
                                                    pausedOverlay={
                                                        <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                    }
                                                    loadingOverlay={
                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                    }
                                                />
                                            </div>

                                            {checkImage === curElem.id ?
                                                <div className="slide-active">
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                : ''
                                            }
                                            <div className="delete-media" onClick={() => openDeletePopUp(curElem.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </div>
                                        </li>

                                    )
                                }) : ''

                            }

                        </ul>
                    </div>
                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={button}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this file
                </SweetAlert>

            </div>

        </>
    );
}

export default UploadMedia;
